.sub-menu-services-mobile
  .szh-accordion
    .razdel
      border-bottom: 1px solid #4E4E4E
      .szh-accordion__item-heading
        .szh-accordion__item-btn
          width: 100%
          background-color: #ffedd9
          border: none
          padding: 5px 27px
          font-family: "cygre_lightlight", sans-serif
          font-size: 20px
      .szh-accordion__item-content
        .szh-accordion__item-panel
          .spec-articles-mobile
            background-color: #ffb825
            ul
              li
                padding: 7px 0
                font-size: 15px
          .diseases-mobile
            background-color: #fff699
            ul
              overflow: visible
              li
                padding: 7px 0
                font-size: 15px
    .szh-accordion__item--expanded
      .szh-accordion__item-heading
        .szh-accordion__item-btn
          background-color: #f39454